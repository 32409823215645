.ativarwhatsappbtnmodal {
	box-shadow: 0px 0px 0px 2px #9fb4f2;
	background:linear-gradient(to bottom, #7892c2 5%, #04225d100%);
	background-color:#04225d;
	border-radius:10px;
	border:1px solid #4e6096;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Georgia;
	font-size:18px;
	padding:13px 37px;
	text-decoration:none;
	text-shadow:0px 1px 0px #04225d;
}
.ativarwhatsappbtnmodal:hover {
	background:linear-gradient(to bottom, #476e9e 5%, #7892c2 100%);
	background-color:#04225d;
}
.ativarwhatsappbtnmodal:active {
	position:relative;
	top:1px;
}
.disabledBTN {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
@media only screen and (max-width: 600px) {
    .ativarwhatsappbtnmodal {
        box-shadow: 0px 0px 0px 2px #9fb4f2;
        background:linear-gradient(to bottom, #7892c2 5%, #04225d100%);
        background-color:#04225d;
        border-radius:10px;
        border:1px solid #4e6096;
        display:inline-block;
        cursor:pointer;
        color:#ffffff;
        font-family:Georgia;
        font-size:14px;
        padding:13px 37px;
        text-decoration:none;
        text-shadow:0px 1px 0px #04225d;
    }
  }