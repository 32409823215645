@media only screen and (max-width: 800px) {
	
	/* Force table to not be like tables anymore */
	#no-more-tables table, 
	#no-more-tables thead, 
	#no-more-tables tbody, 
	#no-more-tables th, 
	#no-more-tables td, 
	#no-more-tables tr { 
		display: block; 
	}
 
	/* Hide table headers (but not display: none;, for accessibility) */
	#no-more-tables thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
 
	#no-more-tables tr { border: 1px solid #ccc; }
 
	#no-more-tables td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
		white-space: normal;
		text-align:left;
		word-wrap: break-word !important;
	}
 
	#no-more-tables td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
		text-align:left;
		font-weight: bold;
	}
 
	/*
	Label the data
	*/
	#no-more-tables td:before { content: attr(data-title); }

	#no-more-tables a {
		color: rgb(8, 42, 87);
		font-family: helvetica;
		text-decoration: none;
		text-transform: uppercase;
	  }
	  
	#no-more-tables a:hover {
		text-decoration: underline;
	  }
	  
	  #no-more-tables  a:active {
		color: black;
	  }
	  
	  #no-more-tables  a:visited {
		color: rgb(2, 28, 58);
	  }

	  
	  #no-more-tables  .pagination a {
		color: black;
		float: left;
		padding: 8px 16px;
		text-decoration: none;
		text-align: center;

	  }
	  
	  #no-more-tables .pagination a.active {
		background-color: #4CAF50;
		color: white;
	  }
	  
	  #no-more-tables  .pagination a:hover:not(.active) {background-color: #ddd;}
}