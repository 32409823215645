* {
    box-sizing: border-box;
  }
  
  html, body, #root, main {
    min-height: 100%;
    height: 100%;
    display: flex;
    flex: 1 auto;
    flex-direction: column;
  }
  
  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body, .btn {
    font-size: 18px;
    line-height: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
  
  pre {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    display: block;
    width: calc(100% - 40px);
    text-align: left;
    margin: 20px 15px;
    padding: 20px;
    border-radius: 10px;
    background-color: #f8f8f8;
    font-size: 14px;
    line-height: 20px;
    overflow-x: scroll;
  }
  
  main {
    text-align: center;
  }
  
  h1 {
    margin: 0;
    padding: 0;
  }
  
  .btn {
    display: inline-block;
    margin: 5px;
    padding: 5px 15px;
    outline: 0;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 0 0 2px rgba(42, 42, 42, .15);
    background-color: rgba(230, 230, 230, .5);
    color: black;
    transition: all 0.2s ease;
    min-width: 120px;
  }
  
  .btn.active:disabled {
    color:#bbb;
  }
  
  .btn.active {
    box-shadow: 0 0 0 4px rgba(49, 132, 253, .5);
  }
  
  section, #start {
    display: flex;
    flex: 1 auto;
    flex-direction: column;
  }
  
  .header {
    padding: 25px 15px 15px;
  }
  
  .tabs {
    padding: 10px 15px 30px;
    overflow: hidden;
    transition: line-height 0.2s ease;
  }
  
  .tabs .btn {
    margin: 0 12px;
    text-transform: capitalize;
  }
  
  #start,
  #not-smart-device {
    padding: 15px;
    flex: 1 auto;
    align-items: center;
    justify-content: center;
    background-color: #eaeaea;
  }
  
  footer {
    padding: 20px 0 60px;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    #readers {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      height: 100vw;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      min-height: 100%; 
    }
  }